/* Override font in material components using variable from variables.scss */
:root {
  --mat-table-background-color: var(--tw-bg-color);
  --mat-table-header-headline-font: var(--tw-font-family);
  --mat-table-row-item-label-text-font: var(--tw-font-family);
  --mat-form-field-container-text-font: var(--tw-font-family);
  --mat-select-trigger-text-font: var(--tw-font-family);
  --mat-option-label-text-font: var(--tw-font-family);
  --mat-card-subtitle-text-font: var(--tw-font-family);
  --mat-paginator-container-text-font: var(--tw-font-family);
  --mdc-typography-font-family: var(--tw-font-family);
}
