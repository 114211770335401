/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import '@digi/arbetsformedlingen/dist/digi-arbetsformedlingen/digi-arbetsformedlingen.css';
@import '@digi/arbetsformedlingen/dist/digi-arbetsformedlingen/fonts/src/fonts.scss';

@import 'assets/scss/debug';
@import 'assets/scss/variables';
@import 'assets/scss/forms';

html, body {
  height: 100%;
}

table {
  th, td {
    &.cell-numeric {
      &.mat-mdc-header-cell:first-of-type, &.mat-mdc-cell:first-of-type {
        padding-left: 0.5rem;
      }

      padding: 0 0.5rem;
      text-align: center;
    }
  }

  .icon-link {
    display: inline-flex;
    align-items: center;

    mat-icon {
      vertical-align: bottom;
    }
  }
}

.f .value {
  .inline {
    vertical-align: middle;
  }

  video {
    height: auto;

    &.inline {
      width: 40%;
    }
  }
}

img, video, object {
  &.dialog {
    width: 100%;
    max-height: 79vh;
  }
}

object {
  &.dialog {
    height: 79vh;
  }
}

.popup {
  &.popup-error {
    --mdc-snackbar-container-color: var(--bs-danger)
  }

  &.popup-warning {
    --mdc-snackbar-container-color: var(--bs-warning)
  }

  &.popup-info {
    --mdc-snackbar-container-color: var(--bs-primary)
  }

  &.popup-success {
    --mdc-snackbar-container-color: var(--bs-success)
  }
}

header {
  .header-nav-container {
    text-align: right;
    align-items: end;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  //background-color: transparent !important;
}

.snackbar-success {
  background-color: var(--bs-success);
}

.snackbar-error {
  background-color: var(--bs-danger);
}

.explanation {
  font-style: italic;
}

