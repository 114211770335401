.debug-panel {
  background: #fdd8;
  font-size: 12px;
  font-family: monospace;
  padding: 2px;

  pre {
    font-size: 11px
  }
}
