// mat-select
pub-root .mat-mdc-form-field-infix {
  width: auto;
  min-width: 200px;
}

.mat-mdc-select-arrow-wrapper {
  margin-left: 5px;
}

.button-container {
  margin-top: 10px;

  button {
    margin-right: 10px;
  }
}
